import React, { useState } from 'react';
import AnalyticsDashboard from './Analytics'; // Replace with the path to your AnalyticsDashboard component

const Authentication = () => {
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleAuthenticate = () => {
    // Replace 'your-password' with the actual password
    if (password === 'SmartThings837') {
      setAuthenticated(true);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      {' '}
      {!authenticated ? (
        <div className="bg-white p-6 rounded shadow">
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Enter password"
            className="block w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300"
          />
          <button
            onClick={handleAuthenticate}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:ring focus:ring-blue-300"
          >
            Authenticate{' '}
          </button>{' '}
        </div>
      ) : (
        <AnalyticsDashboard />
      )}{' '}
    </div>
  );
};

export default Authentication;
