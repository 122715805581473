import { Link } from 'react-router-dom';

const ScenarioDetails = ({ scenarioDetails }) => {
  console.log(scenarioDetails);
  return (
    <>
      {' '}
      {scenarioDetails.startText !== '' && (
        <div className="bg-white shadow-lg rounded-3xl p-4 m-4 mt-16">
          <h3
            className={
              'text-xl ' + (scenarioDetails['steps'] !== '' ? 'mb-2' : '')
            }
          >
            {scenarioDetails.startText}{' '}
          </h3>{' '}
          {scenarioDetails.steps !== '' && (
            <div className="flex items-center justify-between whitespace-pre-line">
              <div
                dangerouslySetInnerHTML={{ __html: scenarioDetails.steps }}
              ></div>{' '}
            </div>
          )}{' '}
        </div>
      )}{' '}
    </>
  );
};
export default ScenarioDetails;
