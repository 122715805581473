import UnderMapButton from '../components/UnderMapButton';
import UnderMapButtonBack from '../components/UnderMapButtonBack';

import Map from '../assets/map.png';
import { RoomData } from '../data/RoomData';

const MapPage = ({ device }) => {
  const devices = [
    // Add more devices here...
  ];

  const RoomButtons = Object.keys(RoomData).map((key) => (
    <UnderMapButtonBack key={key} link={key} room={RoomData[key]} />
  ));
  return (
    <div>
      <h1 className="text-3xl text-white p-4">Map</h1>
      <div className="mb-5 w-11/12 mx-auto min-h-[24rem] max-w-[100%] md:max-w-[30%] md:mb-2 md:min-h-[20rem] mx-auto">
        <img src={Map} />
      </div>

      <div className="container mx-auto grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-2">
        {RoomButtons}
      </div>
    </div>
  );
};

export default MapPage;
