import React, { useRef } from 'react';
import SamsungLogo from '../assets/samsung_logo.jpg';
function PlayerComponent({ videoSrc, closeVideo }) {
  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    vidRef.current.play();
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full z-40 bg-black">
      <div
        className="absolute top-5 left-5 z-50"
        onClick={() => {
          closeVideo();
        }}
      >
        <svg
          id="search_close"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <rect
            id="Basic_Dark_04_Search_01_Icon_search_close_background"
            data-name="Basic_Dark/04 Search/01 Icon/search_close background"
            width="24"
            height="24"
            fill="#010101"
          />
          <path
            id="Cancel_Close"
            data-name="Cancel / Close"
            d="M8.311,7.249,14.282,1.28A.75.75,0,1,0,13.221.22L7.25,6.188,1.281.22A.75.75,0,1,0,.22,1.28L6.19,7.249.22,13.22a.75.75,0,0,0,1.061,1.06L7.25,8.311l5.971,5.969a.75.75,0,0,0,1.061-1.06Z"
            transform="translate(4.749 4.751)"
            fill="#fafafa"
          />
        </svg>
      </div>
      <video
        ref={vidRef}
        playsInline
        autoPlay
        className="video-player"
        poster={SamsungLogo}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  );
}

export default PlayerComponent;
