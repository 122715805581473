import { useState, useEffect } from 'react';
import withCodeActive from '../utils/codeActive';

const Connected = ({ codeActive }) => {
  console.log('Connected: ', codeActive);
  return (
    <div className="top-3 right-3 absolute">
      {codeActive && (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.079 23.4603L27.3581 13.1812C27.9829 12.5563 28.996 12.5563 29.6208 13.1812C30.2456 13.806 30.2456 14.8191 29.6208 15.4439L18.2459 26.8188C17.9244 27.1403 17.5002 27.2964 17.0789 27.2871C16.6577 27.2964 16.2335 27.1403 15.912 26.8188L10.3792 21.286C9.75437 20.6612 9.75437 19.6481 10.3792 19.0233C11.0041 18.3984 12.0171 18.3984 12.642 19.0233L17.079 23.4603Z"
            fill="#11C632"
          />
        </svg>
      )}
      {!codeActive && (
        <span className="text-base text-white text-xs relative top-3">
          Start your journey in onboarding
        </span>
      )}
    </div>
  );
};

export default withCodeActive(Connected);
