import React, { useEffect, useState } from 'react';
import { getSessionStorageValue } from './sessionStorage';
import { isValid } from './api';

function withCodeActive(Component) {
    function WithCodeActive(props) {
        const [codeActive, setCodeActive] = useState(false);

        useEffect(() => {
            const sessionValue = getSessionStorageValue();
            console.log('Session Value: ', sessionValue);
            if (sessionValue) {
                if (sessionValue === '' || !sessionValue) {
                    return;
                }
                isValid(sessionValue).then((isValid) => {
                    if (isValid) {
                        // Do something if the code is valid
                        // console.log('Code is valid');
                        setCodeActive(true);
                    } else {
                        // Do something if the code is invalid
                        console.log('Code is invalid');
                        setCodeActive(false);
                    }
                });

                const interval = setInterval(() => {
                    isValid(sessionValue).then((isValid) => {
                        if (isValid) {
                            // Do something if the code is valid
                            // console.log('Code is valid');
                            setCodeActive(true);
                        } else {
                            // Do something if the code is invalid
                            console.log('Code is invalid');
                            setCodeActive(false);
                        }
                    });
                }, 5000);

                return () => {
                    clearInterval(interval);
                };
            }
        }, [setCodeActive]);

        return ( <
            Component {...props }
            codeActive = { codeActive }
            setCodeActive = { setCodeActive }
            />
        );
    }

    // Export the wrapped component
    return WithCodeActive;
}

export default withCodeActive;