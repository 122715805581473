import UnderMapButton from '../components/UnderMapButton';
import { handleDownload } from '../utils/api';
const DownloadSmartThingsPage = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center bg-gray-800">
      <h1 className="text-3xl text-white p-4 text-center mb-8">
        Download SmartThings
      </h1>

      <div className="container mx-auto grid grid-cols-1 gap-4 p-2">
        {/* iOS Button */}
        <a
          onClick={() =>
            handleDownload(
              'https://apps.apple.com/app/smartthings/id1222822904',
              'ios'
            )
          }
          rel="noopener noreferrer"
        >
          <button className="bg-blue-500 text-white px-4 py-2 rounded-3xl shadow w-full">
            Download for iOS
          </button>
        </a>

        {/* Android Button */}
        <a
          onClick={() =>
            handleDownload(
              'https://play.google.com/store/apps/details?id=com.samsung.android.oneconnect',
              'android'
            )
          }
          rel="noopener noreferrer"
        >
          <button className="bg-blue-500 text-white px-4 py-2 rounded-3xl shadow w-full">
            Download for Android
          </button>
        </a>
      </div>
    </div>
  );
};

export default DownloadSmartThingsPage;
