import React, { useState, useEffect } from 'react';
import { getSessionAnalytics, getInteractionAnalytics } from '../utils/api';
import AnalyticsChart from '../components/AnalyticsChart';
import Chart from 'chart.js/auto'; // Import Chart.js

const Analytics = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sessionCount, setSessionCount] = useState(0);
  const [deviceAnalytics, setDeviceAnalytics] = useState({});
  const [interactionCount, setInteractionCount] = useState(0);
  const [roomInteractions, setRoomInteractions] = useState([]);
  const [sixMonthData, setSixMonthData] = useState(false);
  const [allSessionAnalytics, setAllSessionAnalytics] = useState([]);
  const [allInteractionAnalytics, setAllInteractionAnalytics] = useState([]);

  let interactionChart = null;

  const fetchAnalyticsData = async () => {
    let chartStatus = Chart.getChart('interactionChart');
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    try {
      const sessionAnalyticsResponse = await getSessionAnalytics(
        startDate,
        endDate
      );
      console.log(sessionAnalyticsResponse);
      setDeviceAnalytics(sessionAnalyticsResponse);
      setSessionCount(sessionAnalyticsResponse.count);
      console.log(sessionAnalyticsResponse);

      const interactionAnalyticsResponse = await getInteractionAnalytics(
        startDate,
        endDate
      );
      setRoomInteractions(interactionAnalyticsResponse);

      const totalInteractionCount = interactionAnalyticsResponse.reduce(
        (total, room) => total + room.count,
        0
      );
      setInteractionCount(totalInteractionCount);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
  };

  const createChart = () => {
    const ctx = document.getElementById('interactionChart').getContext('2d');

    const chartData = {
      labels: roomInteractions.map((room) => room.room),
      datasets: [
        {
          label: 'Interaction Count',
          data: roomInteractions.map((room) => room.count),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    const chartConfig = {
      type: 'bar',
      data: chartData,
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            external: customTooltip,
          },
        },
      },
    };

    return new Chart(ctx, chartConfig);
  };

  const updateChart = () => {
    interactionChart.data.labels = roomInteractions.map((room) => room.room);
    interactionChart.data.datasets[0].data = roomInteractions.map(
      (room) => room.count
    );
    interactionChart.update();
  };

  const customTooltip = (context) => {
    const roomIndex = context.dataIndex;
    if (roomInteractions.length > roomIndex) {
      const interactionCount = roomInteractions[roomIndex]?.count || 0;
      return interactionCount.toString();
    }
    return '';
  };

  const fetchInitialSixMonthData = async () => {
    // Helper function to get the start and end dates for the last 6 months
    const getLastSixMonths = () => {
      const dates = [];
      const currentDate = new Date();
      currentDate.setDate(1); // Set to the first day of the current month
      currentDate.setHours(0, 0, 0, 0);

      for (let i = 0; i < 6; i++) {
        const endDate = new Date(currentDate);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0); // Set to the last day of the previous month

        dates.push({
          startDate: new Date(currentDate),
          endDate: new Date(endDate),
          month: currentDate.toLocaleString('default', { month: 'long' }),
        });

        currentDate.setMonth(currentDate.getMonth() - 1);
      }
      return dates;
    };

    const monthsData = getLastSixMonths();
    const allSessionAnalytics = [];
    const allInteractionAnalytics = [];

    for (const { startDate, endDate, month } of monthsData) {
      try {
        const sessionAnalyticsResponse = await getSessionAnalytics(
          startDate,
          endDate
        );
        allSessionAnalytics.push({
          month: month,
          count: sessionAnalyticsResponse.count,
        });

        const interactionAnalyticsResponse = await getInteractionAnalytics(
          startDate,
          endDate
        );

        const totalInteractions = interactionAnalyticsResponse.reduce(
          (total, room) => total + room.count,
          0
        );
        allInteractionAnalytics.push({
          month: month,
          count: totalInteractions,
        });
      } catch (error) {
        console.error(
          'Error fetching analytics data for month:',
          startDate,
          error
        );
      }
    }
    console.log(allSessionAnalytics, allInteractionAnalytics);
    setAllSessionAnalytics(allSessionAnalytics);
    setAllInteractionAnalytics(allInteractionAnalytics);
    setSixMonthData(true);
    // Now, allSessionAnalytics and allInteractionAnalytics are arrays of objects containing month name and associated number.
    // Use them as needed.
  };

  useEffect(() => {
    fetchInitialSixMonthData();

    if (roomInteractions.length > 0) {
      if (!interactionChart) {
        interactionChart = createChart();
      } else {
        let chartStatus = Chart.getChart('interactionChart');

        chartStatus.destroy();
        updateChart();
      }
    }
  }, [roomInteractions]);

  const handleFetchDataClick = () => {
    if (startDate && endDate) {
      fetchAnalyticsData();
    }
  };

  return (
    <div className="analytics-container">
      <h1 className="analytics-header">Analytics Dashboard</h1>
      <div className="date-inputs">
        <div className="date-input">
          <label>Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="date-input">
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <button className="fetch-button" onClick={handleFetchDataClick}>
        Fetch Analytics Data
      </button>
      <div className="analytics-data">
        <div className="analytics-item">
          <h2>Session Analytics</h2>
          <p>Number of QR code scans: {sessionCount}</p>
        </div>
        <div className="analytics-item">
          <h2>Interaction Analytics</h2>
          <p>Number of interactions: {interactionCount}</p>
        </div>
      </div>
      {sixMonthData && (
        <div className="analytics-data">
          <div className="analytics-item">
            <h2 className="breakdown-header">Last 6 Months</h2>
            <AnalyticsChart
              allSessionAnalytics={allSessionAnalytics}
              allInteractionAnalytics={allInteractionAnalytics}
            />
          </div>
        </div>
      )}
      <div className="analytics-data">
        <div className="analytics-item">
          <h2 className="breakdown-header">Interaction Breakdown by Room</h2>
          <canvas id="interactionChart"></canvas> {/* Chart.js canvas */}
        </div>
      </div>

      <div className="analytics-data">
        <div className="analytics-item">
          <h2 className="breakdown-header">Device Analytics</h2>
          <ul>
            {/* Iterate over device analytics keys and display them */}
            {Object.entries(deviceAnalytics).map(([device, count], idx) => {
              if (device !== 'count' && device !== 'tourCount') {
                return (
                  <li key={idx}>
                    {device}: {count}
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </div>
      <div className="analytics-data">
        <div className="analytics-item">
          <h2 className="breakdown-header">Tour Scans</h2>
          <ul>
            {/* Iterate over device analytics keys and display them */}
            {Object.entries(deviceAnalytics).map(([device, count], idx) => {
              if (device === 'tourCount') {
                return <li key={idx}>Tour Count: {count}</li>;
              }
              return null;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
