import { useState } from 'react';
import PlayerComponent from './PlayerComponent';
import ScenarioDetails from './ScenarioDetails';
import { ConditionalWrapper } from './ConditionalWrapper';
import PlayGif from '../assets/play.gif';

const DeviceCard = ({
  scenario,
  setActiveScenario,
  vidOnly,
  elevated,
  elevatedClick,
  activated,
  error,
}) => {
  const [isVideoActive, setIsVideoActive] = useState(false);
  return (
    <>
      <>
        <ConditionalWrapper
          condition={scenario.scenarioDetails}
          wrapper={(children) => (
            <div
              onClick={() => {
                if (!vidOnly) {
                  setActiveScenario(scenario.scenarioDetails);
                }
                if (elevated) {
                  console.log('Here', scenario.sceneId);

                  elevatedClick(scenario.sceneId, scenario.defaultId);
                }
              }}
            >
              {children}
            </div>
          )}
        >
          <div
            className={`bg-gray-50 shadow-lg rounded-3xl p-4 m-4 relative h-[82%]`}
          >
            <div className="pb-2">
              <div
                className="inline-block w-[40px] h-[40px] svg-100"
                dangerouslySetInnerHTML={{ __html: scenario.image }}
              ></div>
              {activated && (
                <div
                  className="inline-block absolute right-[15px] top-[15px]"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsVideoActive(true);
                  }}
                >
                  <img src={PlayGif} width={35} height={35} />
                </div>
              )}
              {scenario.videoUrl && (
                <div
                  className="inline-block absolute right-[15px] top-[15px]"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsVideoActive(true);
                  }}
                >
                  <p className="text-base text-grey text-xs relative top-1 leading-[14px]">
                    See how <br /> it works
                  </p>
                  {/* <svg
                    id="ic_action_play_color"
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 40 40"
                  >
                    <g id="action_bg_active">
                      <rect
                        id="action_action_bg_active_background"
                        data-name="action/action_bg_active background"
                        width="40"
                        height="40"
                        fill="none"
                      />
                      <circle id="Oval" cx="20" cy="20" r="20" fill="#f0f0f0" />
                    </g>
                    <path
                      id="Path_2"
                      data-name="Path 2"
                      d="M.5.5"
                      transform="translate(21.898 25.456)"
                      fill="none"
                      stroke="#979797"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <g id="ic_action_start_color" transform="translate(8 8)">
                      <path
                        id="Triangle"
                        d="M6,0l6,9H0Z"
                        transform="translate(18 6) rotate(90)"
                        fill="#010101"
                        stroke="#010101"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      />
                    </g>
                  </svg> */}
                </div>
              )}
            </div>

            <h3 className="text-xs font-semibold mb-2 pt-2">
              {scenario.title}
            </h3>
            <p className="text-xs text-gray-600">{scenario.description}</p>
            {error && <p className="text-xs text-gray-600">{error}</p>}
          </div>
        </ConditionalWrapper>
        {isVideoActive && (
          <PlayerComponent
            videoSrc={scenario.videoUrl}
            closeVideo={() => {
              setIsVideoActive(false);
            }}
          />
        )}
      </>
    </>
  );
};
export default DeviceCard;
