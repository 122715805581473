import RoomCard from '../components/RoomCard';
import Garden from '../assets/garden.png';
import Kitchen from '../assets/kitchen.png';
import Onboarding from '../assets/onboarding.png';
import { RoomData } from '../data/RoomData';

const RoomsPage = ({ device }) => {
  const RoomButtons = Object.keys(RoomData).map((key) => (
    <RoomCard key={key} link={key} room={RoomData[key]} />
  ));
  return (
    <div>
      <h1 className="text-3xl text-white p-6"> Rooms </h1>
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 p-2">
        {RoomButtons}
      </div>
    </div>
  );
};

export default RoomsPage;
