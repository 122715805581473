import { Link } from 'react-router-dom';

const RoomSelection = ({ room, link, selectRoom, setRoomData }) => {
  return (
    <div className="bg-white shadow-lg rounded-3xl p-4 m-4">
      <img
        src={room.image}
        className="w-60 text-center m-auto rounded-md shadow-lg mb-4"
      />
      <div
        className="flex items-center justify-between mt-4"
        onClick={() => {
          selectRoom(link);
          setRoomData(room);
        }}
      >
        <button className="bg-blue-500 text-white px-4 py-2 rounded-3xl shadow w-full">
          {' '}
          {room.name}{' '}
        </button>{' '}
      </div>{' '}
    </div>
  );
};
export default RoomSelection;
