import { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import HomeImg from '../assets/home.png';
import { getCode, isClaimed } from '../utils/api';
import { RoomData } from '../data/RoomData';
import RoomSelection from '../components/RoomSelection';

function CodeDisplay() {
  const [code, setCode] = useState('');
  const [room, setRoom] = useState('');
  const [roomData, setRoomData] = useState(null);
  console.log('RD: ', roomData);
  const RoomButtons = Object.keys(RoomData).map((key) => (
    <RoomSelection
      key={key}
      link={key}
      room={RoomData[key]}
      selectRoom={setRoom}
      setRoomData={setRoomData}
    />
  ));
  useEffect(() => {
    const fetchData = async () => {
      const newCode = await getCode();
      setCode(newCode);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (code === '') {
      return;
    }

    const interval = setInterval(() => {
      isClaimed(code).then((_isClaimed) => {
        if (_isClaimed) {
          getCode().then((newCode) => {
            setCode(newCode);
          });
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [code]);
  return (
    <>
      {room == '' && (
        <div>
          <h1 className="text-3xl text-white p-6"> Pick Room </h1>
          <div className="container mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 p-2">
            {RoomButtons}
          </div>
        </div>
      )}
      {room !== '' && (
        <>
          <div className="flex h-screen">
            <div className="w-1/2 bg-black text-white p-8 flex flex-col justify-center mx-auto text-center">
              <h1
                className="text-4xl font-bold mb-4"
                data-code={`https://www.837smartthingshome.com/?code=${code}&room=${room}`}
              >
                {roomData.name}
              </h1>
              {code && (
                <QRCode
                  title="SmartThingsAuthenticated"
                  className="mx-auto mb-4 rounded-md shadow-lg bg-white p-1"
                  value={`https://www.837smartthingshome.com/?code=${code}&room=${room}`}
                />
              )}
              <h2 lassName="text-3xl font-bold mb-4">
                Scan here to start your Smart Home journey
              </h2>
            </div>
            <div className="w-1/2 bg-gray-500">
              <img
                src={HomeImg}
                alt="Image"
                className="object-cover object-center h-full"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CodeDisplay;
