const SESSION_STORAGE_KEY = '837_control';

const addToSessionStorage = (value) => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, value);
};

const getSessionStorageValue = () => {
    const serializedValues = sessionStorage.getItem(SESSION_STORAGE_KEY);
    return serializedValues;
};

export { addToSessionStorage, getSessionStorageValue };