import React, { useState, useEffect } from 'react';
import withCodeActive from '../utils/codeActive';

import HomeButton from '../components/HomeButton';
import { claimCode, isValid } from '../utils/api';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import {
  addToSessionStorage,
  getSessionStorageValue,
} from '../utils/sessionStorage';
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const InfoPage = ({ codeActive, setCodeActive }) => {
  let query = useQuery();

  const code = query.get('code');
  const room = query.get('room');

  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [codeEntryActive, setCodeEntryActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  console.log('Code: ', query.get('code'), 'Room: ', query.get('room'));

  function getDeviceInfo() {
    'use strict';

    var module = {
      dataos: [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' },
      ],
      databrowser: [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
      ],
      detect: function () {
        var agent = [
            navigator.platform,
            navigator.userAgent,
            navigator.appVersion,
            navigator.vendor,
            window.opera,
          ].join(' '),
          os = this.matchItem(agent, this.dataos),
          browser = this.matchItem(agent, this.databrowser);

        return { os: os, browser: browser };
      },
      matchItem: function (string, data) {
        var i = 0,
          j = 0,
          regex,
          regexv,
          match,
          matches,
          version;

        for (i = 0; i < data.length; i += 1) {
          regex = new RegExp(data[i].value, 'i');
          match = regex.test(string);
          if (match) {
            regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
            matches = string.match(regexv);
            version = '';
            if (matches) {
              if (matches[1]) {
                matches = matches[1];
              }
            }
            if (matches) {
              matches = matches.split(/[._]+/);
              for (j = 0; j < matches.length; j += 1) {
                if (j === 0) {
                  version += matches[j] + '.';
                } else {
                  version += matches[j];
                }
              }
            } else {
              version = '0';
            }
            return {
              name: data[i].name,
              version: parseFloat(version),
            };
          }
        }
        return { name: 'unknown', version: 0 };
      },
    };

    var result = module.detect();

    return `${result.os.name} | ${result.os.version} | ${result.browser.name}`;
  }

  useEffect(() => {
    const sessionValue = getSessionStorageValue();

    if (sessionValue) {
      const codeToValidate = sessionValue;
      console.log('Code gto validata: ', codeToValidate);
      isValid(codeToValidate).then((isValid) => {
        if (isValid) {
          // Do something if the code is valid
          // console.log('Code is valids');
          setCodeActive(true);
        } else {
          // Do something if the code is invalid
          console.log('Code is invalid');
          setCodeActive(false);
        }
      });
    }
    if (code && room) {
      setCodeEntryActive(true);
      setErrorMessage('Validating QR Scan');
      claimCode(code, getDeviceInfo()).then((res) => {
        console.log(res);
        if (res.claimed) {
          addToSessionStorage(res.code);
          setErrorMessage('Success, redirecting...');
          setTimeout(function () {
            navigate(`/room/${room}`);
          }, 2000);
        } else {
          setErrorMessage('Sorry, unable to validate code.');
        }
      });
    }
  }, []);

  const handleSubmit = () => {
    // Do something with inputValue here
    console.log('Input value:', inputValue);
    claimCode(code, getDeviceInfo()).then((res) => {
      console.log(res);
      if (res.claimed) {
        addToSessionStorage(res.code);
        setErrorMessage('Success, redirecting...');
        setTimeout(function () {
          navigate('/room/outdoor-terrace');
        }, 2000);
      } else {
        setErrorMessage('Sorry, unable to validate code.');
      }
    });
  };

  const handleChange = (event) => {
    setErrorMessage('');
    setInputValue(event.target.value);
  };

  return (
    <div>
      <div className="container pl-5 pt-5 pb-5">
        <HomeButton
          isAction={true}
          actionType="link"
          action="/"
          button="home"
        />
      </div>

      {/* <h1 className="text-3xl font-bold text-white pl-5 pr-6 pb-2">
        Welcome to our SmartThings Home!
      </h1>
      <div className="text-base font-bold text-white pl-6 pr-6 pb-6">
        We believe home is a place you should be able to personalize through
        connectivity. As you visit, feel free to take control and transform the
        environment as you wish.
      </div>
      <div className="text-base font-bold text-white pl-6 pr-6 pb-6">
        Use this app to guide you in each room so you can see where and how to
        experience SmartThings.
      </div>
      <h1 className="text-3xl font-bold text-white pl-5 pr-6 pb-2">
        What is SmartThings?
      </h1>
      <div className="text-base font-bold text-white pl-6 pr-6 pb-6">
        SmartThings is a convenient way to seamlessly control devices at
        anytime, from anywhere. It empowers you to live your everyday life in
        the most efficient, productive and comfortable ways.
      </div> */}
      <div className="bg-white shadow-lg rounded-3xl m-4 youtube-container  min-h-[15rem] md:min-h-[30rem] max-w-[90%] md:max-w-[75%] mx-auto">
        <iframe
          width="100%"
          className="youtube-video"
          src="https://www.youtube.com/embed/Sp6tIaS2i2M"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      {!codeActive && (
        <div className="text-center">
          <h1 className="text-3xl font-bold text-white pl-5 pr-6 pb-2">
            At 837? <br />
            <br />
            Find a QR code & scan to take
            <br /> control of SmartThings!
          </h1>
        </div>
      )}
      {!codeEntryActive && !codeActive && (
        <div className="flex items-center justify-between mt-4 pt-2 pb-6 max-w-[90%] md:max-w-[75%] mx-auto hidden">
          <button
            className="px-4 py-2 rounded-3xl w-full bg-blue-500 text-white px-4 py-2 rounded-3xl shadow w-full"
            onClick={() => {
              setCodeEntryActive(true);
            }}
          >
            Enter code
          </button>
        </div>
      )}
      {codeEntryActive && !codeActive && (
        <div class="relative text-center pb-4 hidden">
          <input
            type="text"
            class="h-14 max-w-[90%] pl-10 pr-20 rounded-lg z-0 focus:shadow focus:outline-none mx-auto rounded-tr-none rounded-br-none"
            placeholder="Enter code"
            value={inputValue}
            onChange={handleChange}
          />
          <div class="inline h-14 ">
            <button
              class="h-14 w-20 text-white rounded-lg bg-blue-500 rounded-tl-none rounded-bl-none"
              onClick={handleSubmit}
            >
              Go
            </button>
          </div>
          {errorMessage && (
            <div className="text-center">
              <h6 className="text-red text-xs pt-1">{errorMessage}</h6>
            </div>
          )}
        </div>
      )}
      {codeActive && (
        <div className="text-center">
          <h4 className="text-xl font-bold text-white pl-5 pr-6 pb-2">
            User authenticated.
          </h4>
        </div>
      )}
      {!codeActive && (
        <div className="text-center pt-12">
          <h1 className="text-sm font-bold text-white text-center">
            Or continue without control...
          </h1>
        </div>
      )}

      <div className="flex items-center justify-between mt-4 pt-0 pb-6 max-w-[90%] max-w-[50%] mx-auto">
        <Link
          to={'/room/outdoor-terrace'}
          className="bg-blue-500 text-white px-4 py-2 rounded-3xl shadow w-full"
        >
          <button className="w-full">Begin Journey</button>
        </Link>
      </div>
    </div>
  );
};

export default withCodeActive(InfoPage);
