import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getSessionStorageValue } from './utils/sessionStorage';
import { isValid } from './utils/api';

import ScrollToTop from './ScrollToTop';

import './App.css';

import MapPage from './pages/Map';
import RoomsPage from './pages/Rooms';
import RoomPage from './pages/Room';
import InfoPage from './pages/Info';
import CodeDisplay from './pages/CodeDisplay';
import Connected from './components/Connected';
import BottomNav from './components/BottomNav';
import Analytics from './pages/Analytics';
import Authentication from './pages/Authentication';
import DownloadSmartThingsPage from './pages/DownloadSmartthings';
import TourScan from './pages/TourScan';
import TourQR from './pages/TourQR';

function App() {
  return (
    <div className="site-wrapper min-h-screen pb-20">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<InfoPage />} />{' '}
        <Route path="/rooms" element={<RoomsPage />} />{' '}
        <Route path="/room/:room/:scenarioDetails?" element={<RoomPage />} />{' '}
        <Route path="/map" element={<MapPage />} />{' '}
        <Route path="/code" element={<CodeDisplay />} />{' '}
        <Route path="/analytics" element={<Authentication />} />{' '}
        <Route path="/tour-scan" element={<TourScan />} />{' '}
        <Route path="/tour-qr" element={<TourQR />} />{' '}
        <Route
          path="/download-smartthings"
          element={<DownloadSmartThingsPage />}
        />{' '}
      </Routes>{' '}
      <BottomNav />
    </div>
  );
}
export default App;
