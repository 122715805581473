import React from 'react';
import { Bar } from 'react-chartjs-2';

const AnalyticsChart = ({ allSessionAnalytics, allInteractionAnalytics }) => {
  const labels = allSessionAnalytics.map((data) => data.month).reverse();

  const sessionData = allSessionAnalytics.map((data) => data.count).reverse();
  const interactionData = allInteractionAnalytics
    .map((data) => data.count)
    .reverse();

  console.log(labels);
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Session Analytics',
        data: sessionData,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Interaction Analytics',
        data: interactionData,
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default AnalyticsChart;
