import React from 'react';
import { Link } from 'react-router-dom';
import { ConditionalWrapper } from './ConditionalWrapper';
function HomeButton({ isAction, actionType, action, button }) {
  return (
    <ConditionalWrapper
      condition={isAction}
      wrapper={(children) => {
        if (actionType === 'function') {
          return (
            <div
              onClick={() => {
                action();
              }}
            >
              {children}
            </div>
          );
        } else if (actionType === 'link') {
          return <Link to={action}>{children}</Link>;
        }
      }}
    >
      {button === 'home' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            d="M0 0H24V24H0z"
            data-name="Basic_ST/Action bar/01 icon/ic_appbar_location background"
          ></path>
          <path
            fill="#fafafa"
            d="M7.5 18.655h-4a1 1 0 01-1-1v-8H1a1 1 0 01-.669-1.744L8.836.257a1 1 0 011.338 0l8.506 7.654a1 1 0 01-.67 1.743h-1.5v8a1 1 0 01-1 1h-4v-6h-4v6z"
            data-name="Combined Shape"
            transform="translate(2.495 2.673)"
          ></path>
        </svg>
      )}
      {button === 'back' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g data-name="Layer 2">
            <path
              fill="#fafafa"
              d="M19 11H7.14l3.63-4.36a1 1 0 10-1.54-1.28l-5 6a1.19 1.19 0 00-.09.15c0 .05 0 .08-.07.13A1 1 0 004 12a1 1 0 00.07.36c0 .05 0 .08.07.13a1.19 1.19 0 00.09.15l5 6A1 1 0 0010 19a1 1 0 00.64-.23 1 1 0 00.13-1.41L7.14 13H19a1 1 0 000-2z"
              data-name="arrow-back"
            ></path>
          </g>
        </svg>
      )}
    </ConditionalWrapper>
  );
}

export default HomeButton;
