import axios from 'axios';
const DEV_URL = 'https://smartthings.herokuapp.com/v1';

// const DEV_URL = 'http://localhost:3001/v1';

const isClaimed = async (codeToValidate) => {
  try {
    const response = await axios.get(
      `${DEV_URL}/session/session-claimed/${codeToValidate}`
    );
    console.log('here: ', response);
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const isValid = async (codeToValidate) => {
  // console.log('Code to val: ', codeToValidate);
  try {
    const response = await axios.get(
      `${DEV_URL}/session/validate/${codeToValidate}`
    );
    // console.log('here: ', response);
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const getCode = async () => {
  const response = await axios.post(`${DEV_URL}/session/`);
  console.log('Res, ', response);
  return response.data.code;
};

const addTour = async () => {
  const response = await axios.post(`${DEV_URL}/session?tour=true`);
  console.log('Res, ', response);
  return response.data.code;
};

const claimCode = async (codeToClaim, userDevice) => {
  try {
    const response = await axios.post(`${DEV_URL}/session/claim`, {
      code: codeToClaim,
      userDevice: userDevice,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const postDownloadDate = async (platform) => {
  try {
    const currentDate = new Date().toISOString(); // current date in ISO string format
    const response = await axios.post(`${DEV_URL}/download/app-download`, {
      date: currentDate,
      platform: platform,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const handleDownload = async (downloadLink, platform) => {
  const success = await postDownloadDate(platform);
  if (success) {
    window.open(downloadLink, '_blank');
  } else {
    console.error('Failed to post date.');
  }
};

const addInteraction = async (code, interaction, sceneId, defaultId) => {
  try {
    const response = await axios.post(`${DEV_URL}/session/add-interaction`, {
      code: code,
      interaction: interaction,
      sceneId: sceneId,
      defaultId: defaultId,
    });
    return response.data;
  } catch (error) {
    console.log('error', error.response.data);
    return error.response.data;
  }
};

const getSessionAnalytics = async (startDate, endDate) => {
  try {
    const response = await axios.get(`${DEV_URL}/session/session-analytics`, {
      params: {
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return { count: 0 };
  }
};

const getInteractionAnalytics = async (startDate, endDate) => {
  try {
    const response = await axios.get(
      `${DEV_URL}/session/interaction-analytics`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { count: 0 };
  }
};

export {
  getCode,
  isClaimed,
  claimCode,
  isValid,
  addInteraction,
  getSessionAnalytics,
  getInteractionAnalytics,
  handleDownload,
  addTour,
};
