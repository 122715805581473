import { useState } from 'react';
import PlayerComponent from './PlayerComponent';
import { ConditionalWrapper } from './ConditionalWrapper';
import { Link } from 'react-router-dom';
const UnderMapButtonBack = ({ link, room }) => {
  // const [activeroo, setActiveroo] = useState(null);
  console.log(room);
  return (
    <Link to={`/room/${link}`}>
      <div className="bg-gray-50 shadow-lg rounded-3xl p-4 m-4 relative min-h-[100px]">
        <div className="pb-2">
          <img className="inline-block w-[30px] pb-2" src={room.icon} />
          {room.videoUrl && (
            <div
              className="inline-block absolute right-2 top-2"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <svg
                id="ic_action_play_color"
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 40 40"
              >
                <g id="action_bg_active">
                  <rect
                    id="action_action_bg_active_background"
                    data-name="action/action_bg_active background"
                    width="40"
                    height="40"
                    fill="none"
                  />
                  <circle id="Oval" cx="20" cy="20" r="20" fill="#f0f0f0" />
                </g>
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M.5.5"
                  transform="translate(21.898 25.456)"
                  fill="none"
                  stroke="#979797"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
                <g id="ic_action_start_color" transform="translate(8 8)">
                  <path
                    id="Triangle"
                    d="M6,0l6,9H0Z"
                    transform="translate(18 6) rotate(90)"
                    fill="#010101"
                    stroke="#010101"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
          )}
        </div>

        <h3 className="text-xs font-semibold mb-2">{room.name}</h3>
        {/* <p className="text-xs text-gray-600">{room.description}</p> */}
      </div>
    </Link>
  );
};
export default UnderMapButtonBack;
