import { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import HomeImg from '../assets/tour.png';
import { addTour } from '../utils/api';
import { RoomData } from '../data/RoomData';
import RoomSelection from '../components/RoomSelection';

function TourQR() {
  return (
    <div className="flex justify-center items-center h-screen text-white text-3xl">
      <img src={HomeImg} />{' '}
    </div>
  );
}

export default TourQR;
