import ScenarioCard from '../components/ScenarioCard';
import HomeButton from '../components/HomeButton';
import PlayerComponent from '../components/PlayerComponent';
import ScenarioDetails from '../components/ScenarioDetails';
import withCodeActive from '../utils/codeActive';
import Connected from '../components/Connected';

import { Link } from 'react-router-dom';
import { RoomData } from '../data/RoomData';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { addInteraction } from '../utils/api';
import { getSessionStorageValue } from '../utils/sessionStorage';

const RoomPage = ({ codeActive }) => {
  const { room, scenarioDetails } = useParams();
  const [activeScenario, setActiveScenario] = useState(null);
  const [error, setError] = useState('');
  const [activated, setActivated] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeScenario]);

  function capitalizeHyphenatedWords(str) {
    return str
      .split('-')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }
  const elevatedClick = async (sceneId, defaultId) => {
    if (!activated && codeActive) {
      console.log('TRIGGERING:');
      setActivated(true);

      const sessionValue = getSessionStorageValue();
      const interactionResponse = await addInteraction(
        sessionValue,
        RoomData[room].name,
        sceneId,
        defaultId
      );
      setError('Activating...');
      console.log(interactionResponse);
      if (interactionResponse.code === 500) {
        setError('Scene is already in use, try again in a minute');
        setTimeout(function () {
          setActivated(false);
          setError('');
        }, 60000);
      } else {
        setTimeout(function () {
          setActivated(false);
          setError('');
        }, 60000);
      }
    }
  };

  return (
    <div>
      <div className="top-section">
        <Connected />

        <div className="container pl-5 pt-5">
          {!activeScenario && (
            <HomeButton
              isAction={true}
              actionType="link"
              action="/"
              button="home"
            />
          )}
          {activeScenario && (
            <HomeButton
              isAction={true}
              actionType="function"
              button="back"
              action={() => {
                setActiveScenario(null);
              }}
            />
          )}
        </div>
        <h1 className="text-3xl text-white pl-6 pr-6 pt-6 pb-4">
          {capitalizeHyphenatedWords(room)}
        </h1>
        {RoomData[room].tagLine && (
          <div className="text-xl text-white pl-6 pr-6">
            {RoomData[room].tagLine}
          </div>
        )}

        <div className="text-base text-white pl-6 pr-6 pb-6">
          {!activeScenario
            ? RoomData[room].roomDescription
            : activeScenario.intro}
        </div>
      </div>
      <div className="main-content">
        {RoomData[room].image && !activeScenario && (
          <div className="mb-5 w-11/12 mx-auto min-h-[15rem] md:max-w-[30rem]">
            <img src={RoomData[room].image} className="rounded-3xl" />
          </div>
        )}
        {!activeScenario && (
          <div class="flex flex-wrap justify-center">
            <div class="w-full md:w-2/3 lg:w-3/4 xl:w-4/5">
              <div
                class={`grid grid-cols-2 sm:grid-cols-2 md:grid-cols-${RoomData[room].scenarios.length} gap-4`}
              >
                {/* {scenarioDetails && } */}
                {RoomData[room].scenarios.map((scenario, index) => {
                  if (codeActive && scenario.elevated) {
                    return (
                      <ScenarioCard
                        key={index}
                        scenario={scenario}
                        vidOnly={scenario.vidOnly}
                        error={error}
                        activated={activated}
                        elevated={true}
                        elevatedClick={elevatedClick}
                      />
                    );
                  }
                  if (!scenario.elevated) {
                    return (
                      <ScenarioCard
                        key={index}
                        scenario={scenario}
                        activated={false}
                        vidOnly={scenario.vidOnly}
                        setActiveScenario={setActiveScenario}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        )}
        {activeScenario && <ScenarioDetails scenarioDetails={activeScenario} />}
        {RoomData[room].nextLink && !activeScenario && (
          <div className="flex items-center justify-between mt-4 p-4 pb-6">
            <Link
              to={RoomData[room].nextLink}
              className="bg-blue-500 text-white px-4 py-2 rounded-3xl shadow w-full md:max-w-[15rem] mx-auto"
            >
              <button className="px-4 py-2 rounded-3xl w-full">
                Next Room
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default withCodeActive(RoomPage);
