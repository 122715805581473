import { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import HomeImg from '../assets/home.png';
import { addTour } from '../utils/api';
import { RoomData } from '../data/RoomData';
import RoomSelection from '../components/RoomSelection';

function TourScan() {
  useEffect(() => {
    const fetchData = async () => {
      const newTour = await addTour();
      console.log('new tour: ', newTour);
    };

    fetchData();
  }, []);

  return (
    <div className="flex justify-center items-center h-screen text-white text-3xl">
      <h1>Scan Successful</h1>
    </div>
  );
}

export default TourScan;
